import { isObjectFitSupported, polyfills } from './polyfills/polyfills';
//import yall from 'yall-js/dist/yall';
import { yall } from './lib/yall-v3.1.6';
import 'what-input';
import 'a11y-toggle';
import closeDropdown from './modules/close-dropdown';
import qtyChange from './modules/qty-change';
import { toggleButton } from './modules/toggle-button';
import menu from './modules/menu';
import mobileMenu from './modules/mobilemenu';
import { init as googleMapsInit } from './modules/googlemaps';
import { modal } from './modules/modal';
import { accordion } from './modules/accordion';
import { searchOffices } from './modules/search-offices';
import { lists } from './modules/lists';
import { cookieConsent } from './modules/cookie-consent';
import { heatpumprecommendation } from './modules/heatpumprecommendation';
import heroVideo from './modules/hero-video';
import AOS from 'aos';
import { splideSlider } from './modules/splideslider';
import { epiformsGA } from './modules/epiforms-ga';
import { newsPressFilter } from './modules/news-press-filter';
import { vacancyPageFilter } from './modules/vacancy-page-filter';
import { newsPagination } from './modules/news-pagination';
import countdownTimer from './modules/countdowntimer';
import initTables from './modules/table';

export const start = () => {
  console.log('App loaded');
  yall({
    events: {
      load: function (event) {
        const element = event.target;

        if (!element.classList.contains('lazy') && element.nodeName == 'IMG') {
          element.classList.add('lazy--loaded');
        }

        if (
          !isObjectFitSupported &&
          element.classList.contains('js-object-fit')
        )
          objectFitPolyfill(element);
      },
    },
    observeChanges: true,
    lazyClass: 'lazy',
  });

  qtyChange.init();
  closeDropdown.init();
  toggleButton();
  menu();
  mobileMenu();
  googleMapsInit();
  modal.init();
  accordion.init();
  searchOffices.init();
  lists.init();
  cookieConsent.init();
  heatpumprecommendation.init();
  splideSlider.init();
  epiformsGA.init();
  newsPressFilter.init();
  vacancyPageFilter.init();
  newsPagination.init();
  countdownTimer.init();
  initTables();

  const heroVideoEl = document.querySelector('.js-hero-video');
  if (heroVideoEl) {
    heroVideo(heroVideoEl);
  }

  AOS.init({
    once: true,
    duration: 600,
    offset: 400,
  });
};

export const app = () => {
  if (!polyfills) {
    start();
  } else {
    Promise.all([...polyfills])
      .then(_ => {
        try {
          start();
        } catch (error) {
          console.error('Failed to start the app', error);
        }
      })
      .catch(error => {
        console.error('Failed fetching polyfills', error);
      });
  }
};

// eslint-disable-next-line no-unused-expressions
document.readyState !== 'loading'
  ? app()
  : document.addEventListener('DOMContentLoaded', app);
